import { Feedback } from '~/models/feedback';

const collection = 'feedback';

export const feedbackService = {
	send: async (
		source: string | null = 'Неизвестный источник',
		name: string = '-',
		phone: string = '-',
		email: string = '-',
		subject: string = '-',
		target: string | null = null
	): Promise<void> => {
		const siteId = '55762'; // ID сайта в calltouch

		const { createItems } = useDirectusItems();

		const items = <Feedback.Model[]>[{ source, name, phone, email, subject, target }];

		if (process.env.NODE_ENV === 'production') {
			await createItems<Feedback.Model>({ collection, items });

			// @ts-ignore
			if (target && typeof ym === 'function') {
				// @ts-ignore
				ym(89991876, 'reachGoal', target);
				// console.info(`feedback.send: ${target}`);
			}

			// @ts-ignore
			if (typeof window['ct'] === 'function') {
				const ct_data = {
					fio: name,
					phoneNumber: phone.replace(/\D/g, ''),
					email: email,
					subject: `proverky.net | ${subject}`,
					tags: source,
					requestUrl: location.href,
					sessionId:
						// @ts-ignore
						window.ct('calltracking_params', 'ix3asbda')?.sessionId || undefined,
				};

				await $fetch(
					`https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
					{ method: 'GET', params: ct_data }
				);
			} else {
				console.info('calltouch fail');
			}
		}
	},
};
